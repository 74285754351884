.preview-holder {
     width: 20vw;
     display: flex;
     justify-content: center;
     flex-direction: column;
     & > * {
          margin: auto;
     }
     .preview {
          width: 180px;
          height: 180px;
          border-radius: 50%;
     }
     .pixels {
          padding: 15px 0;
     }
}

.test {
     width: 600px;
     height: 600px;
}
.MuiAlert-root {
     margin-bottom: 15px;
}
