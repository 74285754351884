label {
     @include sentence-case;
     color: #7b7a86;
     font-size: 0.8rem;
     display: inline-block;
     margin-bottom: 0.4375rem;
     &.required::after {
          color: red;
          content: " *";
     }
}
