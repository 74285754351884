$icon-font-family: "icons" !default;

$icon-company-full-logo: "\e91b";
$icon-company-logo: "\e91c";
$icon-user-group-add: "\e900";
$icon-team: "\e901";
$icon-app: "\e902";
$icon-add-circle: "\e909";
$icon-add-circle-bold-alternate: "\e906";
$icon-add-circle-alternate: "\e907";
$icon-add: "\e908";
$icon-view: "\e90a";
$icon-success: "\e90b";
$icon-swagger-icon-path1: "\e90c";
$icon-swagger-icon-path2: "\e90d";
$icon-swagger-icon-path3: "\e90e";
$icon-upload-icon: "\e90f";
$icon-info-icon: "\e910";
$icon-select-icon: "\e911";
$icon-loader: "\e913";
$icon-home: "\e916";
$icon-pencil: "\e917";
$icon-image: "\e918";
$icon-images: "\e919";
$icon-camera: "\e91a";
$icon-file-picture: "\e927";
$icon-file-zip: "\e92b";
$icon-copy: "\e92c";
$icon-folder-open: "\e930";
$icon-price-tag: "\e935";
$icon-price-tags: "\e936";
$icon-pushpin: "\e946";
$icon-box-add: "\e95e";
$icon-box-remove: "\e95f";
$icon-floppy-disk: "\e962";
$icon-undo: "\e967";
$icon-redo: "\e968";
$icon-user: "\e971";
$icon-users: "\e972";
$icon-user-plus: "\e973";
$icon-user-minus: "\e974";
$icon-user-check: "\e975";
$icon-spinner2: "\e97b";
$icon-spinner3: "\e97c";
$icon-spinner6: "\e97f";
$icon-spinner8: "\e981";
$icon-spinner9: "\e982";
$icon-search: "\e986";
$icon-key: "\e98d";
$icon-cog: "\e994";
$icon-cogs: "\e995";
$icon-stats-dots: "\e99b";
$icon-stats-bars: "\e99c";
$icon-tree: "\e9bc";
$icon-menu: "\e9bd";
$icon-eye: "\e9ce";
$icon-eye-blocked: "\e9d1";
$icon-circle-up: "\ea41";
$icon-circle-right: "\ea42";
$icon-circle-down: "\ea43";
$icon-circle-left: "\ea44";
$icon-checkbox-checked: "\ea52";
$icon-checkbox-unchecked: "\ea53";
$icon-radio-checked: "\ea54";
$icon-radio-hover: "\ea55";
$icon-radio-unchecked: "\ea56";
$icon-filter: "\ea5b";
$icon-share2: "\ea82";
$icon-user1: "\e976";
$icon-circle-up1: "\ea45";
$icon-circle-right1: "\ea46";
$icon-circle-down1: "\ea47";
$icon-circle-left1: "\ea48";
