.flex {
     display: flex;
}
.flex-row {
     @extend .flex;
     flex-direction: row;

     & > * {
          margin: auto;
          margin-left: 5px;
     }
}

.flex-row-center {
     @extend .flex-row;
     & > * {
          margin-left: auto;
     }
}
.flex-col {
     @extend .flex;
     flex-direction: column;
     & > * {
          margin: auto;
          margin-left: 5px;
     }
}

.flex-col-center {
     @extend .flex-col;
     & > * {
          margin-left: auto;
     }
}

.m-auto {
     margin: auto;
}
.m-0 {
     margin: 0 !important;
}
.m-1 {
     margin: 1vw;
}

.m-2 {
     margin: 2vw;
}

.p-1 {
     padding: 15px 1vw;
}
.p-2 {
     padding: 30px 2vw;
}

.left {
     align-self: flex-start;
     align-items: flex-start;
}
.right {
     align-self: flex-end;
     align-items: flex-end;
}
.mx-auto {
     margin-left: auto;
     margin-right: auto;
}
.mx-1 {
     margin-left: 1vw;
     margin-right: 1vw;
}

.mx-2 {
     margin-left: 2vw;
     margin-right: 2vw;
}

.my-auto {
     margin-top: auto;
     margin-bottom: auto;
}

.my-1 {
     margin-top: 1vw;
     margin-bottom: 1vw;
}

.my-2 {
     margin-top: 2vw;
     margin-bottom: 2vw;
}

.mt-0 {
     margin-top: 0;
}

.mt-1 {
     margin-top: 1vw;
}

.mt-2 {
     margin-top: 2vw;
}

.mb-0 {
     margin-bottom: 0;
}

.mb-1 {
     margin-bottom: 1vw;
}

.mb-2 {
     margin-bottom: 2vw;
}

.ml-0 {
     margin-left: 0;
}

.ml-1 {
     margin-left: 1vw;
}

.mlx-6 {
     margin-left: 6px;
}

.ml-2 {
     margin-left: 2vw;
}

.mr-0 {
     margin-right: 0;
}

.mr-1 {
     margin-right: 1vw;
}

.mr-2 {
     margin-right: 2vw;
}
.off {
     display: none !important;
}
.mtpx-1{
     margin-top: 8px;
}

.flex-center {
     display: flex;
     align-items: center;
}

/**
Conversion standards:
    5px -> 0.3256vw;
    10px -> 0.6511vw;
    15px -> 0.977vw;
    30px -> 1.9533vw;


*/
