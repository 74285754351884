.MuiButton-containedPrimary {
     text-transform: capitalize !important;
     border-radius: 2px !important;
     &:not(.Mui-disabled) {
          background-color: #005f9e;
          &:hover {
               background-color: #197cb6;
          }
     }
     &.Mui-disabled {
          cursor: not-allowed;
          pointer-events: visible;
     }
}

.buttonLink {
     background-color: transparent !important;
     text-transform: capitalize !important;
     border-radius: 2px !important;
     &:not(.Mui-disabled) {
          color: #005f9e;
          background-color: transparent;
          &:hover {
               background-color: transparent;
               color: #197cb6;
          }
     }
}
