.accountFrameDialog {
     .MuiDialog-paper {
          padding: 0 !important;

          &.MuiDialog-paperFullScreen {
               iframe {
                    min-height: 100%;
                    height: 100%;
               }
          }

          &:not(.MuiDialog-paperFullScreen) {
               max-height: 640px;
               max-width: 480px;
          }

          border-radius: 8px;
          .MuiDialogContent-root {
               padding-top: 0 !important;
               display: flex;
               flex-direction: column;
          }

          iframe {
               width: 100%;
               min-height: 640px;
               height: 75vh;
               width: 100%;
               height: 100%;
               min-width: 320px;
               border: none;
          }
     }

     .popup-cancel-button {
          cursor: pointer;
          position: absolute;
          padding: 11px 30px;
          color: #0c77be;
          background-color: #fff;
          width: 100%;
          box-sizing: border-box;
          @media only screen and (min-width: 380px) {
               padding: 25px 30px;
          }
     }
}
