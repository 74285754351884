header {
     background-color: #fff;
     flex-grow: 1;
     min-height: 55px;
     max-height: 55px;
     & > .MuiToolbar-root {
          min-height: inherit;
          max-height: inherit;
     }
     .toolbar {
          min-height: 55px;
     }

     .application-name {
          color: $trimble-gray;
          flex-grow: 1;
          text-transform: uppercase;
          font-weight: 600;
     }
     .user-panel {
          color: #555;
     }
}
