footer {
     color: $trimble-gray;
     font-size: 12px;
     line-height: 18px;
     text-align: center;

     .copyright {
          margin-right: 8px;
          @media only screen and (max-width: 500px) {
               display: block;
               margin-bottom: 5px;
          }
     }
     a {
          text-decoration: underline;
          font-weight: normal;
     }
}
