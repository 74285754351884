.card {
     background-color: #fff;
     box-shadow: 1px 1px 5px #b5b4c5;
     box-shadow: 0 2px 4px #aeaeb6;
     box-sizing: border-box;
     overflow-y: auto;
     border-radius: 8px;
     padding: 24px;
     width: 100%;
     max-width: calc(50% - 16px);
     margin-bottom: 30px !important;
     margin-top: 0;
     overflow-y: visible;
     transition: $transition-all;
     &:nth-child(even) {
          float: right;
          @media only screen and (max-width: 768px) {
               margin: auto;
          }
     }
     &:nth-child(odd) {
          float: left;

          @media only screen and (max-width: 768px) {
               margin: auto;
          }
     }

     & > h3 {
          margin-left: 0 !important;
     }

     @media only screen and (max-width: 600px) {
          width: 95%;
          max-width: calc(95% - 16px);
     }

     @media only screen and (min-width: 600px) and (max-width: 768px) {
          width: 75%;
          max-width: calc(75% - 16px);
     }
     /*
     @media only screen and (min-width: 768px) and (max-width: 1024px) {
          height: auto;
          margin: auto;
          position: inherit;
          width: calc(50% - 16px);
     }

     @media only screen and (min-width: 1024px) and (max-width: 1280px) {
          width: 50%;
     }

     @media only screen and (min-width: 1280px) {
          width: 50%;
     }
*/
     .card-body {
          margin: auto;
          width: 100%;
     }
}
