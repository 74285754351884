.card.basics {
     min-height: 309px;

     .name-holder {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          @extend .m-0;
          @extend .mt-0;

          @media only screen and (max-width: 768px) {
               flex-direction: column;
               flex-wrap: wrap;
          }
          .MuiFormControl-root:first-child,
          .MuiSkeleton-rect:first-child {
               margin-right: 16px;
          }
     }
     .photo-block {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          border: 1px solid #7b7a86;
          border-radius: 50%;
          font-size: 3rem;
          margin: 25px auto;
          position: relative;
          height: 104px;
          width: 104px;

          img {
               border-radius: 50%;
               height: 104px;
               width: 104px;
          }
          svg {
               fill: #363544;
               height: 75px;
               width: 75px;
          }
          .personIcon {
               display: flex;
               margin: auto;
          }
          .photo {
               border-radius: 50%;
               box-shadow: 0 4px 4px rgba(10, 16, 20, 0.24), 0 0 4px rgba(10, 16, 20, 0.12);
               box-sizing: border-box;
               height: 100px;
               width: 100px;
          }
          .edit-button {
               background-color: #009ad9;
               bottom: 10px;
               border-radius: 50%;
               cursor: pointer;
               fill: #fff;
               margin: 0;
               position: absolute;
               right: 0px;
               padding: 3px;
               height: 17px;
               width: 17px;
          }
     }
}

@import "./photo-editor/photoEditor";
