.MuiFormControl-root {
     width: 100%;
     margin-bottom: 15px;
     margin-top: 0;
     .MuiFormLabel-root.Mui-focused {
          color: inherit;
     }
     .MuiInput-underline:before {
          border-bottom: 1px solid #e2e2e7 !important;
     }

     .MuiInput-underline:not(.Mui-disabled):hover:before {
          border-bottom: 2px solid #0c77be !important;
     }
     label + .MuiInput-formControl {
          margin-top: 0;
     }
}

.MuiFormControl-root[aria-invalid="false"][aria-label="touched"] {
     .MuiInput-underline:before {
          border-bottom: 2px solid $trimble-green !important;
     }
}

.error,
.MuiFormControl-root[aria-invalid="true"] {
     .MuiInput-formControl:hover,
     .MuiInput-formControl:focus {
          .MuiInput-underline:before {
               border-bottom: 2px solid $trimble-red !important;
          }
     }
     .MuiInput-underline:before {
          border-bottom: 2px solid $trimble-red !important;
     }
     .MuiInput-underline:after {
          border-bottom: 2px solid $trimble-red !important;
     }
}

.MuiInput-formControl[aria-invalid="true"] {
     &:before {
          border-bottom: 2px solid $trimble-red !important;
     }
     &:after {
          border-bottom: 2px solid $trimble-red !important;
     }
}

.MuiInput-formControl[aria-invalid="false"][aria-label="touched"] {
     &:before {
          border-bottom: 2px solid $trimble-green !important;
     }
     &:after {
          border-bottom: 2px solid $trimble-green !important;
     }
}
.form-holder {
     margin-top: 24px;
}
