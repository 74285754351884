.card.data {
     min-height: 328px;

     .form-holder{
          .region-holder {
               margin-bottom: 10px;
               overflow-wrap: break-word;
          }
     
          .link-holder {
               display: flex;
               flex-direction: column;
               .link {
                    margin-bottom: 16px;
                    font-weight: 600;
               }
          }

          .info-holder{
               margin-bottom: 20px;
          }

          .note-content{
               font-size: small;
               margin-bottom: 16px;
          }
     }
}
