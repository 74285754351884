@mixin sentence-case {
     text-transform: lowercase;

     &:first-letter {
          text-transform: uppercase;
     }
}
h2 {
     color: #363545;
     font-size: 22px;
     font-weight: 600;
     @include sentence-case();
}

h3 {
     color: #363545;
     font-size: 16px;
     font-weight: bold;
     margin-top: 0;
     text-transform: capitalize;
     @include sentence-case();
}

h4 {
     color: #363545;
     font-size: 16px;
     font-weight: 600;
}

.notes {
     font-size: 14px;
     color: #363545;
}

.link,
.link:visited {
     color: #005f9e;
     font-size: 14px;
     font-weight: 500;
     text-decoration: none;
     cursor: pointer;
     &:hover {
          color: #00437b;
     }
}

p {
     font-size: 14px;
     color: #363545;
}

.color-inherit {
     color: inherit;
}

.link.inherit-text,
.link.inherit-text:visited {
     color: inherit;
     font-size: 14px;
     font-weight: 500;
     text-decoration: none;
     cursor: pointer;
     &:hover {
          color: inherit;
          text-decoration: underline;
     }
}

.mt-1 {
     margin-top: 8px;
}

.p-1 {
     padding: 8px
}
