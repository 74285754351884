.MuiFormControl-root.MuiTextField-root {
     margin-bottom: 0;
}

.MuiInputBase-input.MuiInput-input {
     border: 1px solid #ccc;
     padding: 12px;
}

.Mui-disabled {
     cursor: not-allowed;
     pointer-events: visible;
}
