@import "./icon-names.scss";

@font-face {
     font-family: "icons";
     src: url("/fonts/icon-fonts.eot?jte44l");
     src: url("/fonts/icon-fonts.eot?jte44l#iefix") format("embedded-opentype"),
          url("/fonts/icon-fonts.ttf?jte44l") format("truetype"), url("/fonts/icon-fonts.woff?jte44l") format("woff"),
          url("/fonts/icon-fonts.svg?jte44l#icons") format("svg");
     font-weight: normal;
     font-style: normal;
     font-display: block;
}

.icon {
     /* use !important to prevent issues with browser extensions that change fonts */
     font-family: "#{$icon-font-family}" !important;
     speak: none;
     font-style: normal;
     font-weight: normal;
     font-variant: normal;
     text-transform: none;
     line-height: 1;
     color: inherit;
     /* Better Font Rendering =========== */
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;

     &:before {
          content: $icon-user-group-add;
          color: inherit !important;
     }
}

.icon-company-full-logo {
     &:before {
          content: $icon-company-full-logo;
     }
}
.icon-company-logo {
     &:before {
          content: $icon-company-logo;
     }
}
.icon-user-group-add {
     &:before {
          content: $icon-user-group-add;
     }
}
.icon-team {
     &:before {
          content: $icon-team;
     }
}
.icon-app {
     &:before {
          content: $icon-app;
          color: #fff;
     }
}
.icon-add-circle {
     &:before {
          content: $icon-add-circle;
     }
}
.icon-view {
     &:before {
          content: $icon-view;
     }
}
.icon-success {
     &:before {
          content: $icon-success;
          color: #4d6f33;
     }
}
.icon-swagger-icon .path1 {
     &:before {
          content: $icon-swagger-icon-path1;
          color: rgb(255, 255, 255);
     }
}
.icon-swagger-icon .path2 {
     &:before {
          content: $icon-swagger-icon-path2;
          margin-left: -1em;
          color: rgb(73, 163, 43);
     }
}
.icon-swagger-icon .path3 {
     &:before {
          content: $icon-swagger-icon-path3;
          margin-left: -1em;
          color: rgb(255, 255, 255);
     }
}
.icon-upload-icon {
     &:before {
          content: $icon-upload-icon;
          color: #555;
     }
}
.icon-info-icon {
     &:before {
          content: $icon-info-icon;
          color: #005f9e;
     }
}
.icon-select-icon {
     &:before {
          content: $icon-select-icon;
     }
}
.icon-loader {
     &:before {
          content: $icon-loader;
     }
}
.icon-home {
     &:before {
          content: $icon-home;
     }
}
.icon-pencil {
     &:before {
          content: $icon-pencil;
     }
}
.icon-image {
     &:before {
          content: $icon-image;
     }
}
.icon-images {
     &:before {
          content: $icon-images;
     }
}
.icon-camera {
     &:before {
          content: $icon-camera;
     }
}
.icon-file-picture {
     &:before {
          content: $icon-file-picture;
     }
}
.icon-file-zip {
     &:before {
          content: $icon-file-zip;
     }
}
.icon-copy {
     &:before {
          content: $icon-copy;
     }
}
.icon-folder-open {
     &:before {
          content: $icon-folder-open;
     }
}
.icon-price-tag {
     &:before {
          content: $icon-price-tag;
     }
}
.icon-price-tags {
     &:before {
          content: $icon-price-tags;
     }
}
.icon-pushpin {
     &:before {
          content: $icon-pushpin;
     }
}
.icon-box-add {
     &:before {
          content: $icon-box-add;
     }
}
.icon-box-remove {
     &:before {
          content: $icon-box-remove;
     }
}
.icon-floppy-disk {
     &:before {
          content: $icon-floppy-disk;
     }
}
.icon-undo {
     &:before {
          content: $icon-undo;
     }
}
.icon-redo {
     &:before {
          content: $icon-redo;
     }
}
.icon-user {
     &:before {
          content: $icon-user;
     }
}
.icon-users {
     &:before {
          content: $icon-users;
     }
}
.icon-user-plus {
     &:before {
          content: $icon-user-plus;
     }
}
.icon-user-minus {
     &:before {
          content: $icon-user-minus;
     }
}

.icon-menu {
     &:before {
          content: $icon-menu;
     }
}

.icon-circle-right1 {
     &:before {
          content: $icon-circle-right1;
     }
}

.icon-circle-right {
     &:before {
          content: $icon-circle-right;
     }
}

.icon-add {
     &:before {
          content: $icon-add;
     }
}
.icon-add-circle-bold-alternate {
     &:before {
          content: $icon-add-circle-bold-alternate;
     }
}
.icon-add-circle-alternate {
     &:before {
          content: $icon-add-circle-alternate;
     }
}
