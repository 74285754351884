@import "./themes/config/colors";
@import "./themes/config/typography";
@import "./themes/config/transitions";
@import "./themes/icons/icon-font";

@import "./themes/utils/loader";
@import "./themes/forms/select";
@import "./themes/general/iframe.scss";

@import "react-image-crop/lib/ReactCrop.scss";

body,
#root,
.layout {
     background-color: #eaeaef;
     font-family: "Open Sans", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-size: 14px;
     height: 100vh;
     margin: 0;
     overflow-y: auto !important;
     padding: 0 !important;
     user-select: none;
     width: calc(100vw - 17px);
     width: 100%;

     @import "./themes/general/layout";
     @import "./themes/general/logo";
     @import "./themes/general/header";
     @import "./themes/general/footer";
     @import "./themes/general/image";
     @import "./themes/forms/button";

     .user-form {
          max-width: 1200px;
          margin: auto;
          flex-grow: 1;
          width: 100%;

          .form-sections {
               display: block;
               padding: 0 24px;

               .row-sections{
                    display: flex;
                    flex-flow: row;

                    @media only screen and (max-width: 768px) {
                         display: flex;
                         margin: auto;
                         flex-direction: column;
                         padding: 0;
                    }

                    .column-sections{
                         display: flex;
                         flex-flow: column;
                         width: 100%;

                         .card{
                              min-width: 95%;
                         }
                    }
               }

          }
          @import "./themes/utils/card";
          @import "./themes/forms/formControl";
          @import "./themes/forms/label";
          @import "./themes/forms/textField";
          @import "./themes/forms/select";
          @import "./themes/forms/error";

          label {
               text-transform: none;
          }
     }
}

.layout {
     display: flex;
     flex-direction: column;
}

@import "./pages/home/home";
code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.footer {
     margin: auto;
     padding: 12px;
     max-width: 100%;
 }
 
 .footer__links {
      text-align: center;  
      a{
           color: #0063A3;
           text-decoration: none;
           &:hover{
                text-decoration: underline;
           }
      }
      .link-style{
           margin: 0 1.3px;
      }
      .link-icon{
           display: inline-flex;
           align-items: center;
           margin: 0 1.3px;  
      }
 }
 .icon-style{
      padding-left: 5px;
 }
 .copyright-info {
      text-align: center;
      color: #6a6e79;
  }
 
