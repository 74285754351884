.loader {
     width: 100%;
     display: flex;
     position: relative;
}

.loader.backdrop {
     width: 100vw;
     height: 100vh;
     display: flex;
     position: fixed;
     top: 0;
     left: 0;
     //background-color: rgba(0, 0, 0, 0.4);
     .loader-container {
          display: flex;
          margin: auto;
          .loader-circle {
               position: static;
          }
     }
}
.loader-circle {
     margin: auto;
     transform: translateY(-30px);
     position: absolute;
     left: calc(50% - 30px);
     top: calc(50% - 30px);
     top: 0;
     opacity: 0;
     margin: 0;
     width: 100px;
     height: 100px;
     animation: loader-circle 1s 0s linear infinite;
     -webkit-animation: loader-circle 1s 0s linear infinite;
     & > div {
          background-color: $trimble-blue-mid;
          border-radius: 100%;
          &:nth-child(2) {
               -webkit-animation-delay: -0.4s;
               animation-delay: -0.4s;
          }
          &:nth-child(3) {
               -webkit-animation-delay: -0.2s;
               animation-delay: -0.2s;
          }
     }
}

@-webkit-keyframes loader-circle {
     0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
     }
     5% {
          opacity: 1;
     }
     100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0;
     }
}
@keyframes loader-circle {
     0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
     }
     5% {
          opacity: 1;
     }
     100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0;
     }
}

@-webkit-keyframes loader-circle {
     0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
     }
     5% {
          opacity: 1;
     }
     100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 0;
     }
}

.loader-circle {
     position: relative;
     -webkit-transform: translateY(-30px);
     transform: translateY(-30px);
     position: absolute;
     left: -30px;
     top: 0;
     opacity: 0;
     margin: 0;
     width: 100px;
     height: 100px;
     animation: loader-circle 1s 0s linear infinite;
     -webkit-animation: loader-circle 1s 0s linear infinite;
}
.loader-circle > div:nth-child(2) {
     -webkit-animation-delay: -0.4s;
     animation-delay: -0.4s;
}
.loader-circle > div:nth-child(3) {
     -webkit-animation-delay: -0.2s;
     animation-delay: -0.2s;
}
.loader-circle > div {
     position: absolute;
     top: 0;
     opacity: 0;
     margin: 0;
     width: 100px;
     height: 100px;
     -webkit-animation: loader-circle 1s 0s linear infinite;
     animation: loader-circle 1s 0s linear infinite;
}

.MuiSkeleton-rect {
     margin-bottom: 15px;
}

.MuiDialog-paper {
     padding: 32px !important;
}

.snackbar {
     margin-top: 40px;
}
