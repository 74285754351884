img {
     transition: all 1s ease-in-out;
}

.img-responsive {
     width: 100%;
}

.ReactCrop__drag-handle::after {
     background-color: #fff;
     border: 1px solid rgba(255, 255, 255, 0.7);
     border-radius: 50%;
}
.MuiDialogContent-root {
     padding: 0;
}
