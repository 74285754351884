// trimble-brand-colors
$trimble-blue-dark: #002d5b;
$trimble-blue: #00437b;
$trimble-blue-mid: #005f9e;
$trimble-blue-light: #009ad9;
$trimble-yellow: #ffbe00;
$trimble-green: #4d6f33;
$trimble-red: #a51d25;
$trimble-gray: #363545;
$trimble-white: #fff;

// theme-colors
$primary: #005f9e;
$secondary: #8c8b96;
$tertiary: #d0d0d7;
$dark: #363545;
$success: #5e9331;
$warning: #ffa500;
$danger: #c81922;

// Gray Colors
$gray-50: #f9f9fb;
$gray-light: #f3f3f7;
$gray-0: #eaeaef;
$gray-1: #e2e2e7;
$gray-2: #d0d0d7;
$gray-3: #bfbfc6;
$gray-4: #aeaeb6;
$gray-5: #9d9da6;
$gray-6: #8c8b96;
$gray-7: #7b7a86;
$gray-8: #6a6976;
$gray-9: #595868;
$gray-dark-1: #262533;
$gray-dark-2: #1b1a26;
$gray-dark-3: #0b0a12;

// ui-colors
$main-background: $gray-light;
$panel-background: $trimble-white;
$active: #0c77be;
$hover: #eaeaef;
$selected: #e5f0f8;
// additional Button colors
$action-hover: #007ed1;

$error-message-color: #b4001d;
$error-message-background: #f4d1d3;
$info-message-background: rgba(0, 95, 158, 0.1);
