.MuiFormControl-root {
     .MuiSelect-select {
          padding-right: 40px;
          border: 1px solid #e2e2e7;
          border-radius: 2px;
          padding-left: 10px;
          padding-right: 24px;
          padding-top: 10px;
          padding-bottom: 10px;
          .MuiSelect-icon {
               right: 10px;
          }
     }

     .MuiSelect-select:focus {
          border: 1px solid #e2e2e7;
          background-color: inherit;
          border-bottom: 2px solid #0c77be;
     }

     .MuiSelect-icon {
          top: calc(50% - 10px);
          right: 10px;
     }
}

.MuiListItem-root.Mui-disabled[tabindex="-1"] {
     display: none;
}
