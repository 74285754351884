.actions {
     padding: 24px;
     display: flex;
     justify-content: flex-end;
     max-width: 1200px;
     margin: auto;
     margin: 0 24x;
     button {
          float: right;
          &:not(:last-child) {
               margin-right: 16px;
          }
     }
}

@import "./basics/basics";
@import "./preferences/preferences";
@import "./accounts/accounts";
